.box-border {
  background: rgba(55, 46, 73, 0.8);
  box-shadow: rgba(112, 0, 255, 0.35) 0px 0px 240px;
  border: 2px solid rgb(98, 66, 102);
  border-radius: 16px;
  padding: 24px;
}

@media screen and (max-width: 640px) {
  .box-border {
    padding: 16px !important;
  }
}

.gla-border {
  border-image-source: linear-gradient(rgb(122, 115, 105) 0%, rgb(234, 196, 139) 51.04%, rgb(122, 105, 105) 100%);
  border-image-slice: 1;
}
