.countdown {
  display: inline-flex;
  margin-bottom: 24px;

  .bloc-time {
    margin: 0 8px;
    text-align: center;
    position: relative;

    .count-title {
      position: absolute;
      bottom: -24px;
      left: 0px;
      right: 0px;
    }
  }

  .figure {
    position: relative;
    float: left;
    height: 48px;
    width: 40px;
    margin: 0px 2px;
    background-color: #fff;
    border-radius: 4px;

    > span {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 40px;
      line-height: 44px;
      font-weight: 700;
      color: #232323;
    }

    .top,
    .bottom-back {
      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        line-height: 44px;
      }
    }

    .top {
      z-index: 3;
      background-color: #f7f7f7;
      transform-origin: 50% 100%;
      -webkit-transform-origin: 50% 100%;
    }

    .bottom {
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: rgba(0, 0, 0, 0.02);
      }
    }

    .bottom-back {
      z-index: 2;
      top: 0;
      height: 50%;
      overflow: hidden;
      background-color: #f7f7f7;

      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .top,
    .top-back {
      height: 50%;
      overflow: hidden;
    }

    .top-back {
      z-index: 4;
      bottom: 0;
      background-color: #fff;
      -webkit-transform-origin: 50% 0;
      transform-origin: 50% 0;

      span {
        position: absolute;
        top: -100%;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .bloc-time {
      margin: 0 4px;
      min-width: 64px;
    }

    .figure {
      height: 32px;
      width: 28px;
      > span {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}
