.MuiFormControl-root {
  > label {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .MuiFormHelperText-root {
    margin: 0px;
    font-size: 14px;
  }
}

.MuiOutlinedInput-notchedOutline > legend {
  width: unset;
}
